import React from "react";
import "./Logo.css";

export const Logo = () => {
    return (
        <svg
            viewBox="-100 -100 1200 1200"
            xmlns="http://www.w3.org/2000/svg"
            className="svgHover"
        >
            <path
                d="m 5270,3290 c -211,-6 -542,-36 -870,-80 -41,-5 -192,-23 -335,-40 -704,-80 -1051,-147 -1670,-323 -126,-36 -259,-73 -295,-81 l -65,-15 70,5 c 68,4 146,14 445,55 147,21 480,76 623,104 42,8 82,15 88,15 6,0 8,-21 4,-57 -3,-32 -12,-141 -20,-243 -16,-193 -70,-532 -87,-544 -7,-5 -369,-80 -488,-101 -111,-19 -125,-24 -128,-43 -6,-40 97,-39 358,3 148,24 220,29 220,15 0,-17 -69,-204 -135,-364 -90,-222 -126,-333 -115,-361 10,-28 54,-43 79,-28 9,5 24,25 33,44 37,73 188,471 227,598 23,74 43,137 45,139 5,4 37,9 371,51 132,16 357,42 500,56 247,25 508,61 623,85 53,12 73,39 30,41 -13,0 -95,4 -183,8 -290,14 -658,-14 -1077,-80 -106,-16 -204,-33 -217,-36 -23,-6 -24,-4 -17,28 29,137 52,374 62,623 5,114 11,179 18,182 28,9 402,59 711,95 552,63 950,119 1170,165 264,54 280,58 280,73 0,19 15,18 -255,11 z"
                transform="matrix(0.12033834,0,0,-0.17985795,-74.058857,606.18244)"
            />
            <path
                d="m 2660,2389 c -13,-6 -27,-14 -31,-20 -9,-15 -222,-65 -402,-94 -89,-15 -297,-51 -462,-80 -165,-30 -352,-63 -415,-74 -63,-11 -164,-29 -225,-40 -60,-11 -164,-27 -229,-36 -149,-21 -166,-27 -166,-60 0,-36 29,-39 190,-21 281,32 731,15 1020,-38 321,-60 661,-197 637,-259 -9,-24 -96,-65 -185,-87 -173,-44 -501,-70 -884,-70 -270,0 -408,7 -408,20 0,3 66,18 148,33 81,15 208,38 282,51 131,24 408,86 560,125 79,20 74,28 -7,11 -374,-75 -700,-134 -833,-150 -274,-33 -330,-48 -330,-90 0,-30 36,-47 135,-64 170,-30 408,-40 612,-27 332,22 515,49 703,102 187,54 250,95 250,163 0,34 -6,46 -48,85 -154,149 -608,271 -1006,271 -60,0 -107,2 -105,5 8,7 416,91 714,146 281,52 409,81 460,107 56,28 85,55 85,79 0,25 -17,29 -60,12 z"
                transform="matrix(0.12033834,0,0,-0.17985795,-74.058857,606.18244)"
            />
        </svg>
    );
};